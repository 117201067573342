<template>
    <div class="box">
        <div class="login-container" :class="{ 'vertical-layout': isLayoutVertical }">
            <div class="login-left">
                <div class="text-box">
                    <h1 v-if="!isLayoutVertical">鑫易付</h1>
                    <h1 v-else>鑫易付代理商后台</h1>
                    <div v-if="!isLayoutVertical" style="font-size: 14px;">
                        <p>欢迎使用鑫易付代理商后台</p>
                        <p>
                            <el-icon color="#67C23A">
                                <SuccessFilled />
                            </el-icon>&nbsp;支付
                        </p>
                        <p>
                            <el-icon color="#67C23A">
                                <SuccessFilled />
                            </el-icon>&nbsp;渠道
                        </p>
                        <p>
                            <el-icon color="#67C23A">
                                <SuccessFilled />
                            </el-icon>&nbsp;营销
                        </p>
                    </div>
                </div>
                <div style="position: absolute; bottom:-50px; color: #fff; font-size: 14px;">© {{ copyrightYear }} All Rights
                    Reserved. 鑫易付</div>
            </div>

            <div class="login-right">
                <div v-if="!isLayoutVertical" style="padding-left: 20px; padding-top: 10px;color:#fff;">
                    <h4>登录:</h4>
                    <p>鑫易付代理商后台</p>
                </div>

                <div class="login-box">
                    <el-form ref="loginForms" :model="loginForm" :rules="loginRules" hide-required-asterisk>
                        <el-form-item prop="phone">
                            <el-input type="text" v-model="loginForm.phone" size="large" placeholder="请输入手机号" clearable/>
                        </el-form-item>

                        <el-form-item prop="password">
                            <el-input type="password" v-model="loginForm.password" size="large" placeholder="请输入密码" clearable show-password/>
                        </el-form-item>

                        <el-form-item prop="verifyCode">
                            <el-input v-if="!isLayoutVertical" v-model="loginForm.verifyCode" placeholder="请输入验证码" clearable
                                size="large" style="width: 60%;" />
                            <el-input v-else v-model="loginForm.verifyCode" placeholder="验证码" clearable size="large"
                                style="width: 45%;" />
                            <SIdentify :contentWidth="100" :contentHeight="40" style="line-height: 10px; margin-left: 10px;" class="s-identify"
                                :identifyCode="identifyCode" @click="refreshCode"></SIdentify>
                        </el-form-item>

                        <el-button type="primary" @click="login" size="large" style="font-size: 16px;width: 100px;"
                            :loading="loginForm.loading">登录</el-button>
                    </el-form>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script setup>
import { ref, onMounted, onBeforeUnmount, getCurrentInstance } from 'vue';
import { useRouter } from 'vue-router'
import { SuccessFilled } from '@element-plus/icons-vue'
import SIdentify from '@/components/CustomSidentify/Sidentify'
import { ElMessage,ElMessageBox } from 'element-plus'
const { proxy } = getCurrentInstance()
const router = useRouter()
const copyrightYear = new Date().getFullYear()
const loginForms = ref()
const loginForm = ref({
    phone: '',
    password: '',
    verifyCode: '',
    loading: false, // 添加loading属性
});
const loginRules = ref({
    phone: [{ required: true, message: '请输入手机号', trigger: 'blur' }, { pattern: /^1[3456789]\d{9}$/, message: '手机号格式不正确', trigger: 'blur' }],
    password: [{ required: true, message: '请输入密码', trigger: 'blur' }],
    verifyCode: [{ required: true, message: '请输入验证码', trigger: 'blur' }]
})

let identifyCode = ref('') //图形验证码
let identifyCodes = ref('1234567890abcdefghijklmnobqrstuvwxyz') //验证码出现的数字和字母

// 生成随机数
const randomNum = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1) + min)
}
// 随机生成验证码字符串
const makeCode = (o, l) => {
    identifyCode.value = ''
    for (let i = 0; i < l; i++) {
        const randomIndex = randomNum(0, o.length - 1)
        identifyCode.value += o[randomIndex]
    }
}

// 更新验证码
const clickCount = ref(0);
const refreshCode = () => {
    identifyCode.value = '';
    makeCode(identifyCodes.value, 4);
    const btn = document.querySelector('.s-identify');
    clickCount.value++;
    if (clickCount.value === 10) {
        ElMessageBox.alert('点击过于频繁', '提示', {
            confirmButtonText: '确定',
            callback: () => {
                console.log(btn);
                clickCount.value = 0;
            }
        });
    }
};

const isLayoutVertical = ref(false);

const handleResize = () => {
    isLayoutVertical.value = window.innerWidth < 800;
};


const login = () => {
    loginForms.value.validate((valid) => {
        loginForm.value.loading = true;
        if (valid && loginForm.value.verifyCode.toLowerCase() === identifyCode.value.toLowerCase()) {
            // 表单校验通过且验证码正确，执行登录逻辑
            proxy.$api.login({ phone: loginForm.value.phone, password: loginForm.value.password }).then(res => {
                if (res.status == 200 && res.success) {
                    const token = {
                        access_token: res.data.access_token,
                        expires_in: res.data.expires_in,
                        token_type: res.data.token_type,
                        begin_time: (Date.parse(new Date()) / 1000)
                    }
                    sessionStorage.setItem('token', JSON.stringify(token))
                    sessionStorage.setItem('agent_user', JSON.stringify(res.data.user))
                    router.push({ path: '/dashboard' })
                } else {
                    ElMessage({ message: `${res.error.message}`, type: 'warning' })
                }
            }).catch(error => {
                ElMessage({ message: '登录失败', type: 'error' })
                console.log(error);
            }).finally(() => {
                loginForm.value.loading = false;
            });
        } else {
            // 表单校验不通过或验证码不正确，提示错误信息
            ElMessage({ message: '未完成填写或验证码错误', type: 'error' })
            loginForm.value.loading = false
            refreshCode()
        }
    });
};



onMounted(() => {
    window.addEventListener('resize', handleResize);
    handleResize();
    identifyCode.value = ''
    makeCode(identifyCodes.value, 4)
});

onBeforeUnmount(() => {
    window.removeEventListener('resize', handleResize);
});
</script>
  
<style lang="less" scoped>
.box {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../../assets/login-background.jpg');
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;

    .login-container {
        margin-top: 10%;
        display: flex;
        width: 800px;
        height: 350px;
        padding-bottom: 20px;
        border-bottom: 1px solid #fff;

        &.vertical-layout {
            flex-direction: column;
            border-bottom: none;

            .login-left {
                order: 1;
                align-items: center;
                margin-bottom: -300px;
            }

            .login-right {
                order: 2;
                margin-top: 5%;
                padding-top: 5%;
                margin-left: 20%;
                margin-right: 20%;
                z-index: 100;
                .login-box {
                    padding-left: 10px;
                    padding-right: 10px;
                }
            }
        }

        .login-left {
            position: relative;
            display: flex;
            flex: 1.5;
            flex-direction: column;
            height: 100%;

            .text-box {
                color: #fff;
            }
        }

        .login-right {
            display: flex;
            flex: 1;
            flex-direction: column;
            background-color: rgba(255, 255, 255, 0.3);
            border-radius: 5px;
            height: 100%;

            .login-box {
                padding-left: 20px;
                padding-right: 20px;
                text-align: center;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }
}
</style>
  